<template>
  <div class="subReasonManage">
    <a-table
      :columns="columns"
      :data-source="reasonsList"
      :pagination="pagination"
      :loading="loading"
      v-show="reasonsList.length > 0"
      :rowKey="
        (record, index) => {
          return index;
        }
      "
      :defaultExpandAllRows="true"
    >
      <template #name="{ record }">
        <a>{{ record.Name || record.name }}</a>
      </template>
      <template #customTitle>
        <span>
          {{ $t("reasonManage.name") }}
        </span>
      </template>
      <template #isActive="{ record }">
        <span>
          <a-tag color="green" v-if="record.IsActive || record.is_active">
            {{ $t("reasonManage.Active") }}
          </a-tag>
          <a-tag color="volcano" v-else>
            {{ $t("reasonManage.InActive") }}
          </a-tag>
        </span>
      </template>
      <template #isWarranty="{ record }">
        <span>
          <span v-if="record.in_warranty == '1'">
            {{ $t("route.Warranty") }}
          </span>
          <span v-else-if="record.in_warranty == '0'">
            {{ $t("route.noWarranty") }}
          </span>
        </span>
      </template>
      <template #action="{ record }">
        <span>
          <a @click="addSubReason(record)" v-if="record.RmaReasonSubreason">
            {{ $t("reasonManage.addSubReason") }}
          </a>
          <a @click="editSubReason(record)" v-if="!record.RmaReasonSubreason">
            {{ $t("table.edit") }}
          </a>
        </span>
      </template>
    </a-table>
    <a-modal
      v-model:visible="visible"
      width="600px"
      :title="$t(`reasonManage.${editType}SubReason`)"
      @ok="handleOk"
      @cancel="closeModel"
      :okText="$t(`table.${editType}`)"
      :cancelText="$t('table.cancel')"
      :confirmLoading="confirmLoading"
    >
      <a-form
        :model="formState"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-item :label="$t('reasonManage.SubReasonBy')">
          <a-select v-model:value="formState.rma_reason_id" style="width: 100%">
            <a-select-option
              v-for="v in reasonsList"
              :value="v.ID"
              :key="v.Name"
              >{{ v.Name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('reasonManage.SubReasonName')">
          <a-input v-model:value="formState.name" />
        </a-form-item>
        <a-form-item :label="$t('reasonManage.SubReasonDesc')">
          <a-input v-model:value="formState.description" />
        </a-form-item>
        <a-form-item :label="$t('reasonManage.IsActive')">
          <a-switch
            v-model:checked="formState.is_active"
            :checked-children="$t('reasonManage.Active')"
            :un-checked-children="$t('reasonManage.InActive')"
          />
        </a-form-item>
        <a-form-item :label="$t('reasonManage.sort')">
          <a-input v-model:value="formState.sort" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { ref, reactive, onMounted, computed } from "vue";
import useRmaRepositories from "@/composables/useRmaRepositories";
import { useI18n } from "vue-i18n";
import { notification } from "ant-design-vue";

export default {
  name: "SystemConfig",
  components: {},
  data() {
    return {};
  },
  setup() {
    const { getAllReasonsList, updateSubReason } = useRmaRepositories();
    const { t, locale } = useI18n({ useScope: "global" });
    const loading = ref(false);
    const visible = ref(false);
    const confirmLoading = ref(false);
    const editType = ref("add");
    const reasonsList = ref([]);
    const columns = computed(() => {
      return [
        {
          dataIndex: "Name",
          key: "Name",
          slots: { title: "customTitle", customRender: "name" },
        },
        {
          title: t("reasonManage.CreatedBy"),
          dataIndex: "CreatedBy",
          key: "CreatedBy",
        },
        {
          title: t("reasonManage.IsWarranty"),
          key: "in_warranty",
          slots: { customRender: "isWarranty" },
        },
        {
          title: t("reasonManage.IsActive"),
          key: "IsActive",
          slots: { customRender: "isActive" },
        },
        {
          title: t("reasonManage.sort"),
          dataIndex: "sort",
          key: "sort",
        },
        {
          title: t("table.actions"),
          key: "action",
          slots: { customRender: "action" },
        },
      ];
    });
    const pagination = computed(() => {
      return {
        pageSize: 20,
      };
    });
    const formState = reactive({
      id: "",
      rma_reason_id: "",
      name: "",
      description: "",
      is_active: true,
      sort: "0",
    });

    onMounted(async () => {
      getReasonData();
    });

    const getReasonData = async () => {
      loading.value = true;
      const result = await getAllReasonsList();
      reasonsList.value = [];
      if (result.success) {
        result.data.forEach((v) => {
          if (v.RmaReasonSubreason.length > 0) {
            v.children = v.RmaReasonSubreason;
          }
        });
        reasonsList.value = result.data;
        formState.rma_reason_id = reasonsList.value[0].ID;
      }
      loading.value = false;
    };

    const addSubReason = (item) => {
      editType.value = "add";
      formState.rma_reason_id = item.ID;
      visible.value = true;
    };
    const editSubReason = (item) => {
      editType.value = "edit";
      formState.id = item.id;
      formState.rma_reason_id = item.rma_reason_id;
      formState.name = item.name;
      formState.description = item.description;
      formState.is_active = item.is_active;
      formState.sort = item.sort;
      visible.value = true;
    };

    const handleOk = async () => {
      if (formState.name == "") {
        notification.error({
          message: "Error",
          description: t("reasonManage.SubReasonNameIsRequired"),
        });
      } else {
        let formData = formState;
        if (!formData.id) delete formData.id;
        formData.sort = Number(formData.sort);
        confirmLoading.value = true;
        const result = await updateSubReason(formData);
        confirmLoading.value = false;
        if (result.success) {
          notification.success({
            message: "Success",
            description: t("reasonManage.SubReasonIsupdated"),
          });
          closeModel();
          getReasonData();
        } else {
          notification.error({
            message: "Error",
            description: result.message,
          });
        }
      }
    };

    const closeModel = () => {
      visible.value = false;
      formState.id = "";
      formState.rma_reason_id = reasonsList.value[0].ID;
      formState.name = "";
      formState.description = "";
      formState.is_active = true;
      formState.sort = 0;
    };

    return {
      reasonsList,
      pagination,
      columns,
      loading,
      visible,
      confirmLoading,
      labelCol: { style: { width: "150px" } },
      wrapperCol: { span: 14 },
      formState,
      editType,
      t,
      locale,
      addSubReason,
      editSubReason,
      handleOk,
      closeModel,
    };
  },
};
</script>
  
<style lang="scss" scoped>
.subReasonManage {
  padding: 10px;
  width: 100%;
  min-height: 100%;
  position: relative;

  .full_screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.3);

    .spin {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}
</style>